<template>
  <v-container fluid class="px-3">
    <ItemDetailNotification
      :loading="loading"
      :notification="getNotification"
    />

    <div v-if="!loading" class="mt-8">
      <v-row>
        <v-col cols="12" class="text-center mb-4">
          <h4 class="font-weight-bold label--text darken-2">
            {{ $t("home_notifications") }}
          </h4>
        </v-col>

        <template v-if="getNotificationRelatedPosts.length > 0">
          <v-col
            cols="12"
            v-for="(notification, index) in getNotificationRelatedPosts"
            :key="`notification-related-posts-${index}`"
          >
            <ListItemNotification
              :notification="notification"
              :read-status="notification.is_read !== 0"
            />
          </v-col>
        </template>

        <v-col cols="12" class="text-center">
          <v-btn
            class="label--text darken-1 label-button-list text-none"
            text
            tile
            small
            plain
            :ripple="false"
            to="/notifications"
            @click="$router.go(-1)"
          >
            <v-icon left size="12">$play</v-icon>
            {{ $t("notice_list") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ItemDetailNotification from "@/components/layout/notifications/partials/ItemDetailNotification";
import ListItemNotification from "@/components/layout/notifications/partials/ListItemNotification";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "NotificationDetail",
  components: {
    ListItemNotification,
    ItemDetailNotification
  },
  computed: {
    ...mapGetters(["getNotification", "getNotificationRelatedPosts"])
  },
  data() {
    return {
      loading: false,
      date: dayjs().format("YYYY/MM/DD")
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("NOTIFICATION_GET_DETAIL", {
          id: this.$route.params.id
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
