<template>
  <v-card :loading="loading" flat class="rounded-0">
    <v-skeleton-loader v-if="loading" type="card-heading" :loading="loading" />

    <v-card-text v-if="!loading">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" class="py-0 px-0">
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-chip
                  @click="filterByCategory"
                  label
                  small
                  :class="labelClasses"
                >
                  {{ notification.category.name }}
                </v-chip>
              </v-col>

              <v-col cols="4" class="text-right">
                <span
                  class="
                    font-weight-regular
                    text-heading-1
                    main--text
                    lighten-4
                  "
                >
                  {{ notification.post_date }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <h3 class="font-weight-bold main--text lighten-1">
            {{ notification.title }}
          </h3>
        </v-col>
        <v-col
          cols="12"
          v-html="notification.description"
          ref="content"
        ></v-col>

        <v-col cols="12">
          <v-container fluid>
            <v-row>
              <v-col
                cols="auto"
                class="px-1 py-1"
                v-for="(tag, index) in notification.tags"
                :key="`tag-${index}`"
              >
                <v-chip @click="filterByTag(tag)" label small>
                  <v-icon left size="16"> mdi-tag </v-icon>
                  {{ tag.name }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ItemDetailNotification",
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    notification: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    labelClasses() {
      if (!this.notification.type) {
        return "";
      }
      return this.notification.type.id.toLowerCase() === "important"
        ? "white--text item-notification-label-text item-notification-label-important text-uppercase"
        : "grey--text text--darken-2 item-notification-label-text font-weight-medium item-notification-label-regular text-uppercase";
    },
  },
  mounted() {
    this.parseOembed();
    this.readNotification();
  },
  updated() {
    this.$nextTick(() => {
      this.parseOembed();
    });
  },
  methods: {
    filterByCategory() {
      this.$store.commit("NOTIFICATION_LIST_TITLE", {
        notificationListTitle: this.notification.category.name,
      });

      return this.$router.push({
        name: "notifications_list_category",
        params: { category_id: this.notification.category.id },
      });
    },
    filterByTag(tag) {
      this.$store.commit("NOTIFICATION_LIST_TITLE", {
        notificationListTitle: tag.name,
      });

      return this.$router.push({
        name: "notifications_list_tag",
        params: { tag_id: tag.id },
      });
    },
    readNotification() {
      if (this.notification.is_read === 0) {
        this.$store
          .dispatch("NOTIFICATION_READ_STATUS_UPDATE", {
            notification_id: this.$route.params.id
          })
          .then(() => {
            this.$store.dispatch("NOTIFICATION_UNREAD_COUNT_GET");
          });
      }
    },
    getVideoId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    parseOembed() {
      let elements = document.getElementsByTagName("iframe");
      
      if (elements.length <= 0) {
        return;
      }

      elements.forEach((element) => {
        element.parentNode.removeChild(element);
      });

      document.querySelectorAll("oembed[url]").forEach((element) => {
        const anchor = document.createElement("iframe");

        const videoId = this.getVideoId(element.getAttribute("url"));

        anchor.setAttribute(
          "src",
          `//www.youtube.com/embed/${videoId}?controls=0&fs=0&modestbranding=1`
        );
        anchor.setAttribute("frameborder", "0");
        anchor.className = "youtube";

        element.appendChild(anchor);
      });
    },
  },
};
</script>

<style scoped>
.item-notification-label-text.v-chip.v-size--small {
  font-size: 14px;
}

.item-notification-label-important {
  background: #c63d34 !important;
  border-color: #c63d34 !important;
}

.item-notification-label-regular {
  background: transparent !important;
  border-radius: 0 !important;
  border: 0;
  border-bottom: #c2c2c2 solid 1px;
  padding-left: 0;
  padding-right: 0;
}

.item-notification-unread--text {
  position: absolute;
  vertical-align: middle;
  color: #f03636;
}

.item-notification-unread {
  background-color: #ffe1e1 !important;
}

.item-notification-label-date {
  font-size: 12px;
}

.item-notification-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-detail-notification-links {
  color: #256cf8;
}

::v-deep img {
  width: 100%;
  height: auto;
}

::v-deep .media {
  width: 100%;
  padding-top: 8px;
  height: 210px;
}

::v-deep .youtube {
  width: 100%;
  height: 100%;
}
</style>
